body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

.btn {
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#topbar {
  background: #fff;
  border-bottom: 1px solid #6ca6a7;
  background-color: white;
}

#topbar .nav-link {
  font-weight: bold;
}

#topbar .logo {
  height: 30px;
}

.login-container {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #e9faff;
}

.login-wrap {
  width: 400px;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3px 20px 0 rgba(0,0,0,.1);
  -moz-box-shadow: 0 3px 20px 0 rgba(0,0,0,.1);
  -webkit-box-shadow: 0 3px 20px 0 rgba(0,0,0,.1);
  -o-box-shadow: 0 3px 20px 0 rgba(0,0,0,.1);
  -ms-box-shadow: 0 3px 20px 0 rgba(0,0,0,.1);
}

.btn-lamia, .btn-lamia:hover, .btn-lamia:active, .btn-lamia:visited {
  background-color: #6daaa9;
  border-color: #6daaa9;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
}

.DraftEditor-root {
  border: 1px solid #ced4da;
  height:300px !important;
  overflow-y: scroll;
  padding:20px;
  font-size: 18px;
  font-family: 'calibri', sans-serif;
}