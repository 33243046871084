.rbc-timeslot-group {
  min-height: 120px ;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  display: none;
}
.rbc-timeview > .rbc-event {
  /* width: 10% !important; */
  border: transparent !important;
}

.rbc-day-slot .rbc-event {
  border: transparent !important;
}
.rbc-day-slot .rbc-event .rbc-event-label {
  display: none;
}

.rbc-day-slot .rbc-event .rbc-event-content {
  width: 9rem !important;
  line-height: unset;
}

.rbc-event {
  background: transparent !important;
  color: #000;
}